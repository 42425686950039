import service from '@/utils/request';

export const queryLog = params => {
    return service.get('/caseinfo/log/query',{
        params,
    })
}

export const queryDetail = params => {
    return service.get('/caseinfo/log/query-by-id',{
        params,
    })
}

export const queryShareDetail = params => {
    return service.get('/caseinfo/log/query-psuh-log',{
        params,
    })
}
