<template>
    <div>
        <div class="search">
            <ul class="search-list">
                <li class="search-item">
                    <span class="key">入卷时间:</span>
                    <div class="value">
                        <el-date-picker clearable size="small" unlink-panels type="datetimerange" 
                        @keydown.native.enter="timeChange" 
                        v-model="time" 
                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChange"
                        value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">案件名称:</span>
                    <div class="value">
                        <el-input clearable size="small" v-model="search.caseName" placeholder="请输入案号" @keydown.native.enter="handleQuery"></el-input>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">操作状态:</span>
                    <div class="value">
                        <el-select clearable size="small" v-model="search.status" @keydown.native.enter="handleQuery">
                            <el-option label="成功" :value="1"></el-option>
                            <el-option label="失败" :value="0"></el-option>
                        </el-select>
                    </div>
                </li>
                <li>
                    <el-button type="primary" size="small" @click="handleQuery">搜索</el-button>
                    <el-button type="info" size="small" @click="resetSearch">重置</el-button>
                </li>
            </ul>
        </div>
        <div>
            <el-table :data="tableData" height="545" :header-cell-style="{'background-color': '#EDEDED','text-align':'center'}" :cell-style="{'text-align':'center'}" v-loading="loading">
                <el-table-column width="100" label="序号">
                    <template slot-scope="scope">
                        <div>{{(search.current - 1) * search.size + scope.$index + 1}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="caseName" label="案号" min-width="280"></el-table-column>
                <el-table-column prop="updateTime" label="操作时间" min-width="160">
                    <template slot-scope="data">
                        <span>{{Date.dateFormat(data.row['@timestamp'].slice(0,-1))}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="source" label="操作厂商" min-width="160"></el-table-column>
                <el-table-column prop="updateTime" label="操作状态" min-width="160">
                    <template slot-scope="data">
                        <span>{{data.row.result ? '成功' : '失败'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="200">
                    <template slot-scope="data">
                        <button class="btn btn-text" @click="operateDetail(data.row)">操作详情</button>
                        <button class="btn btn-text" :class="{'table-disabled': !data.row.result}" :disabled="!data.row.result" @click="shareDetail(data.row)">共享详情</button>
                    </template>
                </el-table-column>
                <template slot="empty">
                    {{hasData}}
                </template>
            </el-table>

        </div>

        <div class="footer">
            <el-pagination background 
                layout="total, sizes, prev, pager, next , jumper" 
                :total="total"
                @size-change="handleQuery"
                @current-change="getLogs"
                :page-size.sync="search.size"
                :current-page.sync="search.current"
            > </el-pagination>
        </div>


        <el-dialog :title="detailTitle" :visible.sync="openOperate" width="80%" >
            <div class="detail-container">
                <p v-for="(item,index) in operateText" :key="index">
                    <span>{{item.message}}</span>
                    <span>{{item.stack_trace}}</span>
                </p>
                
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { queryLog , queryDetail , queryShareDetail} from '@/api/log.js';

export default {
    data(){
        return {
            search: {
                status: '',
                caseName: '',
                current: 1,
                size: 20,
                startTime: '',
                endTime: ''
            },
            total: 0,
            time: [],
            tableData: [],
            loading: false,
            openOperate: false,
            operateText: [],
            detailTitle: ''
        }
    },
    created(){
        this.time = [Date.dateFormat(new Date(new Date().toLocaleDateString()),'YY-mm-dd HH:MM:SS'),Date.dateFormat(Date.now(),'YY-mm-dd HH:MM:SS')];
        this.timeChange();
        this.handleQuery();
    },
    methods: {
        handleQuery(){
            this.search.current = 1;
            this.getLogs();
        },
        getLogs(){
            this.loading = true;
            this.hasData = '';
            queryLog(this.search).then(res => {
                this.tableData = res.records.map(item => {
                    let message = JSON.parse(item.message);
                    const json = message.params.fileList || message.params.fileInfoListVo;
                    return {
                        ...item,
                        message,
                        caseName: json.caseName,
                        source: json.source,
                        result: message.result
                    }
                })
                console.log(this.tableData);
                this.total = res.total;
                if(!res.total){
                    this.hasData = '暂无数据';
                }
            }).catch(err => {
                console.log(err);
                this.tableData = [];
                this.total = 0;
                this.hasData = '暂无数据';
            }).finally(() => {
                this.loading = false;
            })
        },
        resetSearch(){
            Object.keys(this.search).forEach(key => {
                this.search[key] = '';
            })
            this.search.current = 1;
            this.search.size = 10;
            this.time = [];
            this.handleQuery();
        },
        timeChange(){
            this.search.startTime = this.time && this.time[0];
            this.search.endTime = this.time && this.time[1];
        },
        operateDetail(row){        //  操作详情
            queryDetail({ traceId: row.traceId }).then(res => {
                // console.log(res);
                this.operateText = res.map(item => {
                    return {
                        message: item.message,
                        stack_trace: item.stack_trace,
                    }
                });
                this.detailTitle = '操作详情';
                this.openOperate = true;
            })
        },
        shareDetail(row){
            queryShareDetail({
                caseName: row.caseName,
                dateTime: row['@timestamp']
            }).then(res => {
                this.operateText = res.map(item => {
                    return {
                        message: item.message,
                        stack_trace: item.stack_trace,
                    }
                });
                this.detailTitle = '共享详情';
                this.openOperate = true;
            })
        },
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.search-list{
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    .search-item{
        display: flex;
        align-items: center;
        margin-right: 30px;
        margin-bottom: 10px;
        .key{
            margin-right: 10px;
        }
        .value{
            // width: 240px;
            /deep/ .el-select{
                width: 100%;
                .el-select__tags{
                    flex-wrap: nowrap;
                }
            }
            /deep/ .el-date-editor{
                width: 100%;
            }
        }
    }
}
.footer{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
/deep/ .el-pagination__editor.el-input{
    width: 90px;
}

.detail-container{
    height: 600px;
    overflow: auto;
    > p{
        margin-bottom: 15px;
        > span{
            margin-top: 10px;
        }
    }
}
.table-disabled{
    opacity: .4;
    cursor: not-allowed;
}
</style>
